<template>
    <div v-if="!openForm">
        <Modal @closeModal="ToggleModal" v-if="showModal" />
        <button :style="{ ...style, fontSize: !mobile ? {} : '20px' }" @click="ToggleModal">
            <slot>
            </slot>
        </button>
    </div>
    <div v-else>
        <div v-if="googleForm">
            <button @click="openGoogleForm()" :style="{ ...style, fontSize: !mobile ? {} : '20px' }">
                    <slot>
                    </slot>
            </button> 
        </div>
        <div v-else>
            <router-link to="forma">
                <button :style="{ ...style, fontSize: !mobile ? {} : '20px' }">
                    <slot>
                    </slot>
                </button> 
            </router-link>    
        </div>
    </div>
    
</template>

<script>
import Modal from './Modal.vue'

export default {
    data() {
        return {
            showModal: false,
            googleForm: false,
            openForm: true // TRUE kada se otvaraju prijave, a FALSE kada su zatvorene
        }
    },
    components: { Modal },
    props: ['style', 'mobile'],
    methods: {
        ToggleModal() {
            this.showModal = !this.showModal
        },
        openGoogleForm(){
            window.open("https://docs.google.com/forms/d/1T4qEpSFHJFb-U-qHvdNZoM7OXe-4HiAcr7gcZ3LKnCI/edit");
        }
    }
}

</script>

<style scoped>
button {
    font-size: 25px;
    color: black;
    font-weight: bold;
    padding: 12px 20px 12px 20px;
    background: linear-gradient(to right, #38EF7D, #11998E, #38EF7D);
    border-radius: 50px;
    cursor: pointer;
    background-position: left;
    background-size: 200%;
    transition: background 0.5s ease-in-out, transform 0.3s ease-in-out;
}


button:hover {
    background-position: right;
}
</style>