<template>
  <div id="background">
    <div id="preFormText" style="text-align: left">
      <p style="text-align: center">Soft Skills Academy 2025</p>
      <p style="font-weight: 400">
        Soft Skills Academy - Unapredi svoje veštine je besplatan seminar mekih
        veština za sve studente u Beogradu, koji organizuje Udruženje studenata
        elektrotehnike Evrope (EESTEC) - Lokalni komitet Beograd. Ovo je
        internacionalni projekat koji se održava već 16 godina zaredom!
      </p>
      <p style="font-weight: 400">
        Projekat traje 3 dana, od 25. do 27. aprila. Svakog dana biće održana po
        dva treninga na kojima ćete imati priliku da usavršite svoje
        profesionalne i lične veštine. Treninge i radionice drže internacionalni
        sertifikovani EESTEC treneri.
      </p>
      <p style="font-weight: 400; color: #11998e">
        Ako želiš da unaprediš veštine:
      </p>
      <div>
        <ul style="font-weight: 400">
          <li>komunikacije</li>
          <li>rada u timu</li>
          <li>rešavanje problema</li>
          <li>kritičkog mišljenja</li>
          <li>prezentovanja</li>
          <li>aktivnog slušanja i učenja</li>
          <li>fleksibilnosti</li>
          <li>prilagodljivosti</li>
          <li>raspolaganja i organizacije vremena</li>
        </ul>
      </div>
      <p style="font-weight: 400">onda je ovo prava prilika za tebe!</p>
      <p style="font-weight: 400">
        Prijavi se za Akademiju mekih veština i ne propusti jedinstvenu priliku
        da unaprediš svoje veštine, koje ne možeš naučiti u toku formalnog
        obrazovanja!
      </p>
      <p style="font-weight: 400">
        Prisustvom na 5 od ukupno 6 treninga dobićeš sertifikat koji potvrđuje
        uspešno završeno učešće na Akademiji mekih veština!
      </p>
      <p style="font-weight: 400">Vidimo se od 25. do 27. aprila u Palati Nauke (Kralja Milana 11, Beograd)!</p>
      <p style="font-weight: 400">
        Za više informacija možeš nas kontaktirati putem instagrama
        <a href="https://www.instagram.com/ssa_belgrade/" style="color: #11998e"
          >@ssa_belgrade</a
        >.
      </p>
    </div>
    <form>
      <label>IME I PREZIME</label>
      <input type="text" v-model="nameAndSurname" required />
      <label>E-MAIL</label>
      <input type="email" v-model="email" required />
      <label>TELEFON</label>
      <input type="tel" v-model="telephone" required />
      <label>Fakultet</label>
      <input type="text" v-model="faculty" required />
      <label>Godina studija (broj)</label>
      <input type="text" v-model="yearOfStudy" required />
      <label>Da li imaš poseban režim ishrane</label>
      <input type="text" v-model="ishrana" required />
      <label>Kako si saznao za SSA</label>
      <input type="text" v-model="ssaPolje" required />

      <!-- <label>Koji od treninga te interesuju</label>
      <div v-for="training in trainings" :key="training.id">
        <div class="training-checkbox-container">
          <input
            type="checkbox"
            :id="'trening-' + training.id"
            :value="training.id"
            v-model="selectedTraining"
            @change="sortSelectedTraining"
            class="training-checkbox"
          />
          <label class="checkBoxText" :for="'trening-' + training.id"
            >{{ training.trainer }} - {{ training.nameOfTraining }}</label
          >
        </div>
      </div> -->

      <label>Motivaciono pismo (do 150 reci)</label>
      <textarea
        required
        id="motivational-letter"
        :oninput="checkWordLimit(150)"
        placeholder="Napišite svoje motivaciono pismo ovde..."
        v-model="textAreaWords"
      ></textarea>
      <div id="word-count">Preostale reči: {{ leftNumOfWords }}</div>
      <br />
      <label>CV (u .pdf formatu)</label>
      <input
        required
        @change="takeCV"
        type="file"
        accept=".pdf, application/pdf"
        ref="fileInput"
      />

      <div
        class="training-checkbox-container"
        style="padding-top: 10px; padding-bottom: 0px; margin-bottom: 0px"
      >
        <input
          type="checkbox"
          class="training-checkbox"
          v-model="deljenjePodataka"
        />
        <label class="checkBoxText"
          >Slažem se sa deljenjem svojih podataka u svrhu organizacijskih
          potreba</label
        >
      </div>
    </form>

    <button @click="slanjeReq" id="posaljiPrijavu">Pošalji prijavu</button>
  </div>
</template>

<script scoped>
const axios = require("axios");

export default {
  data() {
    return {
      leftNumOfWords: 150,
      nameAndSurname: "",
      email: "",
      telephone: "",
      faculty: "",
      yearOfStudy: "",
      textAreaWords: "",
      ishrana: "", //nisam mogao da se setim kako se kaze na engleskom
      ssaPolje: "", //ni ovo
      CVime: null,
      CV: "",
      deljenjePodataka: false, //ni ovo
      trainings: [
        { id: 1, trainer: "Trener 1", nameOfTraining: "Trening 1" },
        { id: 2, trainer: "Trener 2", nameOfTraining: "Trening 2" },
        { id: 3, trainer: "Trener 3", nameOfTraining: "Trening 3" },
        { id: 4, trainer: "Trener 4", nameOfTraining: "Trening 4" },
        { id: 5, trainer: "Trener 5", nameOfTraining: "Trening 5" },
        { id: 6, trainer: "Trener 6", nameOfTraining: "Trening 6" },
      ],
      selectedTraining: [], // Ovo čuva ID-jeve izabranih treninga
    };
  },
  methods: {
    // Provera broja unetih reci u odeljak za pismo preporuke
    checkWordLimit(maxNumOfWords) {
      var words = this.textAreaWords.trim().split(/\s+/); // Razdvaja reči pomoću razmaka
      if (words[0] === "") {
        words = []; // Ako je unos prazan, postavite reči kao prazan niz
      }

      if (words.length > maxNumOfWords) {
        words = words.slice(0, maxNumOfWords); // Ukloni suvišne reči
        this.textAreaWords = words.join(" "); // Ponovno postavi vrednost textarea
      }

      this.leftNumOfWords = maxNumOfWords - words.length;
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Provera da li je fajl PDF
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension === "pdf") {
          this.fileName = file.name;
        } else {
          alert("Molimo vas da izaberete PDF fajl.");
          event.target.value = ""; // Resetovanje input polja
        }
      }
    },
    sortSelectedTraining() {
      this.selectedTraining.sort((a, b) => a - b);
    },
    takeCV(event) {
      this.CVime = event.target.files[0].name;
      this.CV = event.target.files[0];
    },
    frontCheck() {
      if (!this.nameAndSurname || !this.nameAndSurname.trim()) {
        console.log(this.nameAndSurname);
        alert('Nije uneto obavezno polje "IME I PREZIME".');
        return false;
      }
      if (!this.email || !this.email.trim()) {
        alert('Nije uneto obavezno polje "E-MAIL".');
        return false;
      }
      if (!this.telephone || !this.telephone.trim()) {
        alert('Nije uneto obavezno polje "IME I PREZIME".');
        return false;
      }
      if (!this.faculty || !this.faculty.trim()) {
        alert('Nije uneto obavezno polje "FAKULTET".');
        return false;
      }
      if (!this.yearOfStudy || !this.yearOfStudy.trim()) {
        alert('Nije uneto obavezno polje "GODINA STUDIJA".');
        return false;
      }
      if (!this.ishrana || !this.ishrana.trim()) {
        alert('Nije uneto obavezno polje "DA LI IMAŠ POSEBAN REŽIM ISHRANE".');
        return false;
      }
      if (!this.ssaPolje || !this.ssaPolje.trim()) {
        alert('Nije uneto obavezno polje "KAKO SI SAZNAO ZA SSA".');
        return false;
      }
      if (!this.textAreaWords || !this.textAreaWords.trim()) {
        alert('Nije uneto obavezno polje "MOTIVACIONO PISMO".');
        return false;
      }
      if (!this.CV) {
        alert('Nije uneto obavezno polje "CV".');
        return false;
      }
      if (!this.deljenjePodataka) {
        alert("Niste dozvolili deljenje podataka.");
        return false;
      }
      return true;
    },
    resetForm() {
      this.nameAndSurname = "";
      this.email = "";
      this.telephone = "";
      this.faculty = "";
      this.yearOfStudy = "";
      this.textAreaWords = "";
      this.ishrana = "";
      this.ssaPolje = "";
      this.deljenjePodataka = false;
      this.CV = null;
      this.$refs.fileInput.value = "";
      this.selectedTraining = [];
      this.leftNumOfWords = 150;
    },
    async slanjeReq() {
      if (!this.frontCheck()) return;

      let response;
      let result;
      const formData = new FormData();
      formData.append("pdf", this.CV);
      // const backendUrl = "http://localhost:3000";

      // Upload file
      try {
        response = await fetch(`https://softskillsacademy.rs/backend/upload`, {
          method: "POST",
          body: formData,
        });
        if (this.CV && this.CV.size > 5242880) {
          // 5MB = 5242880 bytes
          alert("Veličina PDF fajla prelazi 5MB. Molimo postavite manji fajl.");
          return; // Stop the process if the file is too large
        }
        if (!response.ok) {
          throw new Error(`${response.statusText}`);
        }
        result = await response.json();
      } catch (error) {
        // Handle file upload error
        console.error("Error uploading the file:", error);
        alert(`Error uploading the file: ${error.message}`);
        return; // Stop further execution if file upload fails
      }

      // Prepare form data
      const data = {
        nameAndSurname: this.nameAndSurname.trim(),
        email: this.email.trim(),
        telephone: this.telephone.trim(),
        faculty: this.faculty.trim(),
        yearOfStudy: this.yearOfStudy.trim(),
        ishrana: this.ishrana.trim(),
        ssaPolje: this.ssaPolje.trim(),
        textAreaWords: this.textAreaWords.trim(),
        selectedTraining: this.selectedTraining,
        CV: result.fileNamame,
        deljenjePodataka: this.deljenjePodataka,
      };

      // Submit form data
      try {
        response = await axios.post(`https://softskillsacademy.rs/backend/api/forma`, data);

        // Check if response is successful
        if (!response.data.uspesno) {
          throw new Error(response.data.poruka || "Form submission failed");
        }
        this.resetForm();
        alert("Forma uspešno prihvaćena!");
      } catch (error) {
        // Handle form submission error
        alert("Error submitting the form:", error);

        // Handle different cases for error responses
        if (error.response) {
          // Backend error response (e.g., 400, 500, etc.)
          alert(
            `Error from server: ${
              error.response.data.poruka || "Unknown error from server"
            }`
          );
        } else if (error.request) {
          // Request was made but no response received
          alert("No response from the server. Please try again later.");
        } else {
          // Some other error occurred
          alert(`Greška: ${error.message}`);
        }
      }
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  #background {
    width: 100%;
    background-image: url("../assets/home_hero_bg.png");
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column; /* Stavlja elemente jedan ispod drugog */
    align-items: center; /* Centriranje po širini */
    justify-content: center; /* Centriranje po visini, ako treba */
  }
  form {
    width: 50%;
    display: inline-block;
    margin: 50px 0 40px;
    background: white;
    padding: 100px 60px 80px;
    padding-top: 30px; /* Bez pitanja molim */
    border-radius: 10px;
    text-align: left;
    position: relative;
    z-index: 2;
  }
  label {
    color: #11998e;
    display: block;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 25px 0 15px;
    padding-left: 6px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #11998e;
    border-radius: 2px;
    font-size: 1.2em;
    color: #555;
  }
  input:focus {
    outline: 3px solid #11998e;
    border-bottom: none;
  }
  textarea {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border: 1px solid #11998e;
    border-radius: 2px;
    font-size: 20px;
    height: 350px;
    color: #555;
    resize: none;
  }
  textarea:focus {
    outline: 2px solid #11998e;
    border-bottom: none;
  }
  #word-count {
    padding-top: 5px;
    color: #555;
  }

  #preFormText {
    width: 50%;
    background: white;
    text-align: center;
    padding: 20px;
    margin-top: 150px;
    border-radius: 10px;
  }

  #preFormText p,
  #preFormText div {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
    color: #11998e;
    letter-spacing: 1px;
    margin: 25px 0 15px;
    padding-left: 6px;
    font-weight: bold;
  }

  #preFormText,
  form {
    width: 60%; /* Oba elementa imaju istu širinu */
    box-sizing: border-box; /* Osigurava da padding ne utiče na širinu */
  }

  #posaljiPrijavu {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  #background {
    width: 100%;
    background-color: #11998e;
    display: flex;
    flex-direction: column; /* Stavlja elemente jedan ispod drugog */
    align-items: center; /* Centriranje po širini */
    justify-content: center; /* Centriranje po visini, ako treba */
    padding: 10px 0; /* Razmak oko sadržaja */
  }
  form {
    display: inline-block;
    margin: 50px 0 10px;
    background: white;
    padding: 100px 30px 80px;
    padding-top: 30px; /* Bez pitanja molim */
    border-radius: 10px;
    text-align: left;
    position: relative;
    z-index: 2;
  }
  label {
    color: #11998e;
    display: block;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 25px 0 15px;
    padding-left: 6px;
    font-weight: bold;
  }
  input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #11998e;
    border-radius: 2px;
    font-size: 1.2em;
    color: #555;
  }
  input:focus {
    outline: 3px solid #11998e;
    border-bottom: none;
  }
  textarea {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border: 1px solid #11998e;
    border-radius: 2px;
    font-size: 20px;
    height: 600px;
    color: #555;
    resize: none;
  }
  textarea:focus {
    outline: 2px solid #11998e;
    border-bottom: none;
  }
  #word-count {
    padding-top: 5px;
    color: #555;
  }

  #preFormText {
    width: 50%;
    background: white;
    text-align: center;
    font-weight: bold;
    color: #11998e;
    padding: 20px;
    margin-top: 60px;
    border-radius: 10px;
  }

  #preFormText p {
    color: #11998e;
    display: block;
    font-size: 1em;
    letter-spacing: 1px;
    margin-top: 25px;
    padding-left: 6px;
    font-weight: bold;
  }

  #preFormText,
  form {
    width: 80%; /* Oba elementa imaju istu širinu */
    box-sizing: border-box; /* Osigurava da padding ne utiče na širinu */
  }

  #posaljiPrijavu {
    margin-bottom: 5px;
  }
}

button {
  font-size: 25px;
  color: black;
  font-weight: bold;
  padding: 12px 20px 12px 20px;
  background: linear-gradient(to right, #38ef7d, #11998e, #38ef7d);
  border-radius: 50px;
  cursor: pointer;
  background-position: left;
  background-size: 200%;
  transition: background 0.5s ease-in-out, transform 0.3s ease-in-out;
}

button:hover {
  background-position: right;
}

.training-checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px; /* razmak između checkbox-a i teksta */
  margin-bottom: 10px;
}

.training-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #11998e;
  margin-left: 10px;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  cursor: pointer;
  position: relative; /* Dodano za pozicioniranje pseudo-elementa */
}

.training-checkbox:checked {
  background-color: #11998e;
}

.checkBoxText {
  font-size: 1.1em;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-transform: none;
}
</style>
